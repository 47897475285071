.pc {
  display: block;
}

.mb {
  display: none;
}

.display-device {
  display: flex;
}

.content-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

/* -------------------------- Header --------------------------  */

.bg-navbar {
  background-image: url(../../../src/Assets/Images/bg-navbar-pc.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding-top: 10px;
  padding-right: 10px;
}

.box-group-left {
  width: 30px;
  height: auto;
}

.navbar-center {
  text-align: center;
}

.navbar-right {
  text-align: right;
  justify-content: right;
  margin-top: 8px;
}

.navbar-left {
  text-align: left;
  justify-content: center;
  margin-top: 17px;
}

.main-navbar {
  margin-left: 20px;
  margin-right: 20px;
}

.left-navbar {
  margin-left: 10px;
  margin-right: 10px;
}

/* left */
.icon-group1 {
  width: 30px;
  height: auto;
}

.icon-group2 {
  width: 35px;
  height: auto;
}

/* center */
.icon-group3 {
  width: 70px;
  height: auto;
}

.icon-group4 {
  width: 70px;
  height: auto;
}

.icon-group5 {
  width: 100px;
  height: auto;
}

.icon-group6 {
  width: 70px;
  height: auto;
}

.icon-group7 {
  width: 70px;
  height: auto;
}

/* right */
.icon-group8 {
  width: 130px;
  height: auto;
}

.main-icon {
  margin-bottom: 10px;
}

/* -------------------------- Footer --------------------------  */

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.bg-footer {
  background-image: url(../../../src/Assets/Images/footer-play.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 160px;
}

.bg-footer-promotion {
  background-image: url(../../../src/Assets/Images/footer-play-promotion.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 190px;
}

.link-footer a {
  height: 190px;
  display: block;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* -------------------------- Start Home --------------------------  */

.bg-home {
  background-image: url(../../../src/Assets/Images/bg-pc.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 180px;
}

.img-slide .carousel-item img {
  width: 100%;
}

.box-slide {
  width: 60%;
  height: auto;
}

.box-size-slide {
  width: 560px;
  height: auto;
}

.box-game {
  width: 40%;
  height: auto;
  display: grid;
  justify-content: start;
}

.box-center {
  display: flex;
  text-align: center;
  justify-content: center;
}

.box-img-head {
  width: 232px;
  height: auto;
  margin-bottom: 20px;
}

.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-50 {
  width: 50%;
  height: auto;
  object-fit: cover;
}

.img-content-game {
  width: 165px;
  height: auto;
  margin-bottom: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.btn-content-game {
  width: 328px;
  height: auto;
}

.mt-40 {
  margin-top: 40px;
}

.group-botton {
  margin-bottom: 20px;
  margin-top: 20px;
}

.alert-home {
  background-image: url(../../../src/Assets/Images/border-banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  position: relative;
  height: 55px;
  margin: 20px 0 10px 0;
  /* color: aliceblue; */
}

.alert-marquee {
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 70%;
  /* margin-top: 12px; */
}

.icon-close {
  width: 22px;
  height: auto;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .bg-navbar {
    background-image: url(../../../src/Assets/Images/bg-navbar-pc.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding-top: 5px;
    padding-right: 6px;
  }

  /* center */
  .icon-group3 {
    width: 50px;
    height: auto;
  }

  .icon-group4 {
    width: 50px;
    height: auto;
  }

  .icon-group5 {
    width: 50px;
    height: auto;
  }

  .icon-group6 {
    width: 50px;
    height: auto;
  }

  .icon-group7 {
    width: 50px;
    height: auto;
  }

  .navbar-right {
    text-align: right;
    justify-content: right;
    margin-top: 3px;
  }

  .navbar-left {
    text-align: left;
    justify-content: center;
    margin-top: 12px;
  }

  .main-icon {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 990px) {
  .pc {
    display: none;
  }

  .mb {
    display: block;
  }

  .icon-navbar {
    display: flex;
  }

  /* left */
  .icon-group1 {
    width: auto;
    height: 26px;
  }

  .icon-group2 {
    width: auto;
    height: 30px;
  }

  .icon-group5 {
    width: auto;
    height: 78px;
  }

  .left-navbar {
    margin-left: 5px;
    margin-right: 5px;
  }

  .bg-navbar {
    background-image: url(../../../src/Assets/Images/bg-navbar-mb.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding-top: 30px;
    padding-right: 0px;
  }

  .main-icon {
    margin-bottom: -3px;
  }

  .mt-icon {
    margin-top: 2px;
  }

  .display-device {
    display: block;
  }

  .box-slide {
    width: 100%;
    height: auto;
  }

  .box-card {
    padding-left: 15px;
    padding-right: 15px;
  }

  .btn-play {
    width: 220px;
    height: auto;
  }

  .box-game {
    width: 3%;
    height: auto;
    display: unset;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .btn-play {
    width: 45%;
    height: auto;
  }
}