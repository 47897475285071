* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "sukhumvitSetMedium";
  /*Can be any text*/
  src: local("sukhumvitSet-Medium"),
    url("../Fonts/sukhumvit/ttf/SukhumvitSet-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "sukhumvitSetBold";
  /*Can be any text*/
  src: local("sukhumvitSet-Bold"),
    url("../Fonts/sukhumvit/ttf/SukhumvitSet-Bold.ttf") format("truetype");
}



@font-face {
	font-family: "NotoSansThai-Regular";
	src: local("NotoSansThai-Regular") format("woff"),
			url("../Fonts/NotoSansThai-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansThai-ExtraBold";
	src: local("NotoSansThai-ExtraBold") format("woff"),
		url("../Fonts/NotoSansThai-ExtraBold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansThai-Bold";
	src: local("NotoSansThai-Bold") format("woff"),
		url("../Fonts/NotoSansThai-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansThai-Thin";
	src: local("NotoSansThai-Thin") format("woff"),
		url("../Fonts/NotoSansThai-Thin.ttf") format("truetype");
}

* {
  font-family: "NotoSansThai-Regular";
}

.bg-main-header {
  background-image: url(../../Assets/Images/bg-top-nav.png);
  /* background-color: #B5D3ED; */
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  overflow: hidden;
}

.logoDna-pc {
  width: 160px;
  margin: 15px auto;
}

.memu-navbar-center {
  margin-right: 35px;
}

.section-main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 30px;
  /* background-color: #EB4B45; */
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 2rem 2rem 0rem;
  justify-content: flex-end;
}

.modal-body {
  padding: 20px 20px 30px 20px;
}

.close-btn {
  font-size: 1.5rem;
  background-color: rgb(0 0 0 / 0%);
  background-image: none;
  border: none;
  color: aliceblue;
}

.icon-close {
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.alert-home {
  background-image: url(../../../src/Assets/Images/border-banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 100%;
  overflow: hidden;
  position: relative;
  height: 55px;
  margin: 0px 0 10px 0;
  /* color: aliceblue; */
}

.alert-marquee {
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 40% !important;
  margin-top: 19px !important;
}

.ant-alert {
  background-color: unset;
  border: unset;
  color: #a81d35;
  padding: 8px 5px;
}

.session-menu-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0px auto;
  /* background-color: black; */
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: center;
  margin-bottom: 20px;
}

/* .react-multi-carousel-list li {
  flex: 1 1 auto;
  position: relative;
  width: 10% !important;
} */

.carousel-game {
  width: 80%;
  margin: 0 auto !important;
}

.carousel-card {
  margin-left: 20px;
  margin-left: 20px;
  width: 160px;
}

.css-1pl39hn {
  width: 80% !important;
}

.image-carousel-game {
  width: 100%;
}

.img-promotion-memu {
  width: 232px;
  height: auto;
}

.pc {
  display: block;
}

.mb {
  display: none;
}

.session-title-category {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0px auto;
  /* background-color: black; */
}

.justify-center {
  justify-content: center;
}

.session-nav-category {
  background-image: url(../../Assets/Images/border-menu-promotion.png);
  /* background-color: #B5D3ED; */
  height: 30px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto 30px auto;
  padding: 20px 0;
}

.title-popup {
  padding-top: 14px;
  color: #fcb110;
}

.sapce-noti {
  margin-bottom: 400px;
  margin-top: 100px;
}

.sapce-burger {
  margin-bottom: 200px;
}

/* -------------------------- Start Home --------------------------  */

.home-image {
  background-image: url(../../../src/Assets/Images/bg-pc.png);
  /* background-image: url(../../../src/Assets/Images/queen-desktop.png); */
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
}

.bg-main-footer {
  position: fixed;
  bottom: 0;
  background-image: url(../../Assets/Images/footer-navbar.png);
  background-color: #000000;
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 120px;
  background-position: bottom;
}

.promotion-bg-image {
  background-image: url(../../../src/Assets/Images/bg-pc.png);
  /* background-image: url(../../../src/Assets/Images/queen-desktop.png); */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  min-height: 100vh;
}

.home-section {
  margin-top: 50px;
}

.home-footer-section {
  padding-bottom: 60px;
}

/* -------------------------- End Home --------------------------  */

/* -------------------------- Start Promotion --------------------------  */

.div-main-promotion-page {
  padding-bottom: 70px;
}

.session-title-promotion {
  text-align: center;
  margin: 50px 0px 10px 0px;
}

.seesion-title-catgory {
  background-image: url(../../Assets/Images/border-menu-promotion.png);
  /* background-color: #B5D3ED; */
  height: 30px;
  width: 24%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto 30px auto;
  padding: 20px 0;
}

.seesion-title-catgory p {
  color: #fcb110;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.text-notification {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
}

.session-banner-promotion {
  text-align: center;
}

.box-banner-promotion {
  display: flex;
  width: 32%;
  margin: auto;
  padding-bottom: 20px;
}

.image-promotion {
  width: 75%;
}

.box-banner-promotion img {
  border-radius: 14px;
}

.active-promotion-category p {
  color: white !important;
}

/* -------------------------- End Promotion --------------------------  */

.session-menu-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin: 0px auto;
  /* background-color: black; */
}

.bg-modal-bar {
  background-image: url(../../../src/Assets/Images/bg-pc.png);
  /* background-image: url(../../../src/Assets/Images/queen-desktop.png); */
  /* background-color: rgba(23, 32, 62, 0.7); */
  /* background-image: none; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  border: none;
}

.bg-modal-bar .modal-header {
  border-bottom: none;
}

.modal-dialog {
  margin: 0;
  max-width: 100%;
}

.Burger-Image {
  width: 100%;
  height: 100%;
}

.content-menu-row.mb {
  display: none;
}

.content-menu-row.pc {
  display: flex;
}

.content-menu-row {
  margin: .5rem 1.5rem;
  background-image: url(../../Assets/Images/Game/bg-games-menu.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.text-menu {
  margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-Promotion {
  margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-game-hot {
  margin: 30px auto 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-look-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-game-hot {
  width: 33%;
  margin: 0 auto;
}

.img-look-all {
  width: 14%;
  margin: 0rem auto 1.2rem;
}

.img-look-all:hover,
:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.2em);
  cursor: pointer;
}

.burgerbar:hover {
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  .pc {
    display: none;
  }

  .mb {
    display: block;
  }

  .section-main-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 20px;
    /* background-color: #EB4B45; */
  }

  .bg-main-header {
    background-image: url(../../Assets/Images/navbar-mb.png);
    background-color: #000000;
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    overflow: hidden;
  }

  .home-image {
    background-image: url(../../../src/Assets/Images/bg-mb.png);
    /* background-image: url(../../../src/Assets/Images/queen-mobile.png); */
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    padding-bottom: 200px;
    background-size: 100% 100%;
  }

  .promotion-bg-image {
    background-image: url(../../../src/Assets/Images/bg-mb.png);
    /* background-image: url(../../../src/Assets/Images/queen-mobile.png); */
    height: auto;
    background-repeat: no-repeat;
    background-position: cover;
    background-color: #030204;
    background-attachment: fixed;
    background-size: 100% 100%;
  }

  .logo-navbar-mb {
    margin: 0 auto;
    padding-right: 80px;
  }

  .logoDna-mb {
    width: 100%;
    margin: 20px auto;
  }

  .carousel-mb {
    margin-top: 10px;
  }

  .content-menu-row.pc {
    display: none;
  }

  .content-menu-row.mb {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .content-menu-row.mb div {
    width: 46%;
  }

  .content-menu-right {
    margin: auto 0;
  }

  .text-menu {
    margin: 20px 0 10px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .img-game-hot {
    width: 80%;
    margin: 0 auto;
  }

  .img-look-all {
    width: 40%;
    margin: 0rem auto 1.2rem;
  }

  .box-banner-promotion {
    display: flex;
    width: 50%;
    margin: auto;
    padding-bottom: 20px;
  }

  .seesion-title-catgory {
    background-image: url(../../Assets/Images/border-menu-promotion.png);
    /* background-color: #B5D3ED; */
    height: 30px;
    width: 38%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto 30px auto;
    padding: 20px 0;
  }

  .space-promotion {
    padding-bottom: 200px;
  }

  .home-section {
    margin-top: 0px;
  }

  .content-menu-row {
    background: none;
  }
}

@media screen and (max-width: 768px) {
  .box-banner-promotion {
    display: inline-block;
    width: 60%;
    margin: auto;
  }

  .image-promotion {
    width: 100%;
  }

  .seesion-title-catgory {
    background-image: url(../../Assets/Images/border-menu-promotion.png);
    /* background-color: #B5D3ED; */
    height: 30px;
    width: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto 30px auto;
    padding: 20px 0;
  }

  .seesion-title-catgory p {
    color: #fcb110;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }

  .session-menu-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0px auto;
    /* background-color: black; */
  }

  .alert-home {
    background-image: url(../../../src/Assets/Images/border-banner.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80% 100%;
    overflow: hidden;
    position: relative;
    height: 50px;
    margin-top: 10px;
    /* color: aliceblue; */
  }

  .ant-alert {
    background-color: unset;
    border: unset;
    color: #a81d35;
    padding: 5px 5px;
  }

  .icon-close {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .content-menu-row.pc {
    display: none;
  }

  .content-menu-row.mb {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .content-menu-row.mb div {
    width: 50%;
  }

  .content-menu-right {
    margin: auto 0;
  }

  .text-menu {
    margin: 20px 0 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .seesion-title-catgory {
    background-image: url(../../Assets/Images/border-menu-promotion.png);
    /* background-color: #B5D3ED; */
    height: 30px;
    width: 60%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto 30px auto;
    padding: 20px 0;
  }

}

@media screen and (max-width: 576px) {
  .seesion-title-catgory {
    background-image: url(../../Assets/Images/border-menu-promotion.png);
    /* background-color: #B5D3ED; */
    height: 30px;
    width: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto 30px auto;
    padding: 20px 0;
  }

  .seesion-title-catgory p {
    color: #fcb110;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }

  .box-banner-promotion {
    display: inline-block;
    width: 70%;
    margin: auto;
  }

  .image-promotion {
    width: 100%;
  }

  .img-promotion-memu {
    width: 232px;
    height: auto;
  }

  .sapce-noti {
    margin-bottom: 240px;
    margin-top: 80px;
  }

  .sapce-burger {
    margin-bottom: 70px;
  }

  .alert-marquee {
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 68% !important;
    margin-top: 19px !important;
  }
}

@media screen and (max-width: 400px) {
  .sapce-noti {
    margin-bottom: 380px;
    margin-top: 80px;
  }

  .sapce-burger {
    margin-bottom: 270px;
  }
}

.link-footer {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  max-width: 150px;
  max-height: 150px;
}

.link-footer.link-1 {
  background-image: url(../../Assets/Images/link-item/p-a1.png);
}

.link-footer.link-1:hover {
  background-image: url(../../Assets/Images/link-item/a-a1.gif);
}

.link-footer.link-2 {
  background-image: url(../../Assets/Images/link-item/p-a2.png);
}

.link-footer.link-2:hover {
  background-image: url(../../Assets/Images/link-item/a-a2.gif);
}

.link-footer.link-3 {
  background-image: url(../../Assets/Images/link-item/p-a3.png);
}

.link-footer.link-3:hover {
  background-image: url(../../Assets/Images/link-item/a-a3.gif);
}

.link-footer.link-4 {
  background-image: url(../../Assets/Images/link-item/p-a4.png);
}

.link-footer.link-4:hover {
  background-image: url(../../Assets/Images/link-item/a-a4.gif);
}

.link-footer.link-5 {
  background-image: url(../../Assets/Images/link-item/p-a5.png);
}

.link-footer.link-5:hover {
  background-image: url(../../Assets/Images/link-item/a-a5.gif);
}










a{
  text-decoration: none;
}
.login-container-bg {
  background-image: url(../../Assets/Images/new-landing/bg-pc-login.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  position: fixed;
  margin: auto;
  height: 100vh;
  max-width: 100%;
  overflow-y: auto;
}
.login-container > .login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
}
.login-wrapper .flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: sticky;
}
.login-wrapper .box-menu .menu {
  width: calc(100% - 320px);
  height: 74px;
  justify-content: left;
  padding: 0 15px;
  border-radius: 62px;
  background: var(--red-900-2, #2b0001);
  box-shadow: -4px -4px 8px 0px #53090999, 4px 4px 8px 0px #0000004d;
  border: 0.5px solid;
  border-image-source: linear-gradient(89.82deg, rgba(186, 138, 69, 0) 0.08%, #f6e588 49.47%, rgba(186, 138, 69, 0) 99.84%);
  border-image-slice: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.shadow-login.btn-login{
  border-radius: 50px;
  width: 159px;
  height: 56px;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0;
}
.login-register.flex{
  width: 320px;
}
a.sub-menu.active {
  border-radius: 50px;
  box-shadow: inset 5px 5px 5px 0px #200001, inset -5px -5px 5px 0px #410505, 5px 5px 5px 0px #18020257, -3px -3px 5px 0px #4105053b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper a.sub-menu {
  font-size: 13px;
  width: 193px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: auto;
  font-weight: 600;
  /* font-family: "NotoSansThai-ExtraBold"; */
}
a.sub-menu.active .text {
  font-family: "NotoSansThai-Bold";
  background: -webkit-linear-gradient(0.5turn, rgb(186, 138, 69), rgb(246, 229, 136), rgb(186, 138, 69));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: auto;
  font-size: 13px;
  text-align: center;
  font-weight: 600;
}
.login-register .btn-login {
  background-color: #2b0001;
  border-radius: 50px;
  width: 159px;
  height: 56px;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0;
  box-shadow: 5px 5px 10px 0px #0000005e, -3px -3px 15px 0px #6507047a;
}
.login-register .btn-login, .login-register .btn-register {
  font-size: 15px;
}
.text-gold {
  font-family: "NotoSansThai-Bold";
  background: -webkit-linear-gradient(0.5turn, rgb(186, 138, 69), rgb(246, 229, 136), rgb(186, 138, 69));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.login-wrapper .flex .left {
  width: 552px;
}
.login-container .login-logo > img {
  position: relative !important;
  object-fit: contain;
  width: 100%;
}

.logo-login-right {
  background-image: url(../../Assets/Images/new-landing/logo-login.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  height: 145px;
  width: 384px;
  margin: auto;
}
.logo-login-right-gif {
  width: 80px !important;
  height: 108px !important;
  position: absolute;
  left: 54%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.landing-detail-- {
  width: 534px;
  height: 272px;
  color: #fff;
  margin: auto;
  border-radius: 34px;
  background: var(--red-900-2, #2b0001);
  box-shadow: -4px -4px 8px 0px #530909bf, 4px 4px 8px 0px #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .flex .right .landing-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 502px;
  height: 240px;
  color: #fff;
  margin: auto;
  border-radius: 24px;
  box-shadow: -4px -4px 8px 0px #53090999 inset, 4px 4px 8px 0px #0000004d inset;
}
.login-wrapper .flex .right .landing-detail p {
  font-size: 16px;
  margin-bottom: 0px;
}
.box-group-landing {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}
.login-wrapper .btn-login-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 259px;
  height: 48px;
  color: #fff;
  margin: auto;
  border-radius: 34px;
  background: var(--red-900-2, #2b0001);
  box-shadow: -4px -4px 8px 0px #530909bf, 4px 4px 8px 0px #000000a1;
}
.login-wrapper .btn-register-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 259px;
  height: 48px;
  color: #fff;
  margin: auto;
  border-radius: 34px;
  background: linear-gradient(89.82deg, #ba8a45 0.08%, #f6e588 49.47%, #ba8a45 99.84%);
  box-shadow: -4px -4px 8px 0px #53090999, 4px 4px 8px 0px #0000004d;
}
.login-wrapper .btn-register {
  border-radius: 50px;
  width: 159px;
  height: 54px;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0px 1rem 15px;
  color: #2b0001;
  background: linear-gradient(89.82deg, #ba8a45 0.08%, #f6e588 49.47%, #ba8a45 99.84%);
  box-shadow: -4px -4px 8px 0px #53090999, 4px 4px 8px 0px #0000004d;
  font-size: 15px;
  font-weight: 700;
}
a.btn-register-bottom .text {
  font-family: "NotoSansThai-Bold";
  color: #540018;
}
.hidden {
  display: none;
}


.games-select {
  display: flex;
  justify-content: center;
  width: 1136px;
  height: 124px;
  margin: 0 auto 2rem;
}
.games-select .game {
  display: flex;
  justify-content: left;
  width: 208px;
  height: 118px;
  background-image: url(../../Assets/Images/new-landing/menu/landing/bg-games2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1.25rem 1.5rem;
  box-shadow: -7px -8px 10px rgba(83, 9, 9, 0.6);
  margin: 1rem 0.5rem;
  border-radius: 20px;
  cursor: pointer;
}
.games-select .game .img {
  width: 90px;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 10px;
  margin-top: -10px;
}
.games-select .game.active {
  background-image: url(../../Assets/Images/new-landing/menu/landing/bg-games2-active.png);
}

.games-select .game.hit .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/hit.webp);
}
.games-select .game.sport .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/sport.webp);
}
.games-select .game.casino .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/casino.webp);
}
.games-select .game.lotto .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/lotto.webp);
  margin-top: -15px;
}
.games-select .game.slot .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/slot.webp);
  margin-top: 2px;
}

.games-select .game.hit:hover .img, .games-select .game.hit.active .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/hit.gif);
}
.games-select .game.sport:hover .img, .games-select .game.sport.active .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/sport.gif);
  width: 115px;
}
.games-select .game.casino:hover .img, .games-select .game.casino.active .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/casino.gif);
  width: 115px;
}
.games-select .game.lotto:hover .img, .games-select .game.lotto.active .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/lotto.gif);
  width: 115px;
}
.games-select .game.slot:hover .img, .games-select .game.slot.active .img {
  background-image: url(../../Assets/Images/new-landing/menu/landing/slot.gif);
  width: 115px;
}
.games-select .game .title {
  font-size: 20px;
  margin: 1.25rem 0 1rem 0px;
  width: 85px;
  text-align: center;
  color: #fff;
}
.games-select .game.active .title {
  font-family: "NotoSansThai-Bold";
  background: -webkit-linear-gradient(0.5turn, rgb(186, 138, 69), rgb(246, 229, 136), rgb(186, 138, 69));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.games-list-section {
  text-align: center;
  position: relative;
}
.box-game-login {
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  gap: 16px;
}
.games-box-img .img-games {
  width: 100%;
  height: 100%;
}
.games-box-img {
  width: 176px;
  height: 176px;
}
.games-box-img .img-games {
  width: 100%;
  height: 100%;
}





/* Swiper */

/* .swiper {
	width: 100%;
	height: 100%;
} */

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: transparent;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto !important;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper {
	width: 100%;
	height: 300px;
	margin-left: auto;
	margin-right: auto;
}

.swiper-slide {
	background-size: cover;
	background-position: center;
}

.mySwiper2 {
	width: 100%;
	height: auto;
}

.mySwiper {
	height: 20%;
	box-sizing: border-box;
	padding: 10px 0;
}

.mySwiper .swiper-slide {
	width: 25%;
	height: 100% !important;
	opacity: 0.5;
}

.mySwiper .swiper-slide-thumb-active {
	opacity: 1;
}

.swiper-button-prev,
.swiper-button-next {
	background-repeat: no-repeat;
	background-size: 100% 100%;
	color: transparent !important;
	width: 56px !important;
	height: 56px !important;
  z-index: 9999;
}
.swiper-button-prev {
	/* background-image: url("https://fs.cdnxn.com/lsm99queen-next/bg-btn-swiper-l.png"); */
	background-image: url("../../Assets/Images/bg-btn-swiper-l.png");
}
.swiper-button-next {
	background-image: url("../../Assets/Images/bg-btn-swiper-r.png");
}

.swiper-slide img.swiper-main-image {
	display: block;
	width: 800px;
	height: 100%;
	object-fit: cover;
	margin: auto;
}
.swiper-wrapper {
  position: relative;
	margin: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
}
.swiper-main-section {
	width: 100% !important;
}



.mySwiperThumb{
  width: 800px;
  left: 0;
  text-align: center;
}
.mySwiperMb2{
  height: 100vh;
}


.affiliate-section {
  display: flex;
  justify-content: center;
}
.affiliate-section .affiliate-left, .affiliate-section .affiliate-right {
  width: 50%;
  margin: 1rem 0.25rem;
}
.title-gold-img {
  display: flex;
  justify-content: center;
  text-align: center;
}
.title-gold-img img.title-gold-left, .title-gold-img img.title-gold-right {
  height: 66px;
  width: auto;
  margin: 0 1rem;
}
.title-gold-img h4 {
  font-size: 33px;
}
.login-page .sub-title-gold-pattern {
  font-weight: 300;
  font-size: 20px;
  font-family: "NotoSansThai-Regular";
  color: #fff;
}
.login-register-section {
  text-align: center;
  margin: 1rem 0 0;
  display: flex;
  justify-self: center;
  align-items: center;
}
.login-wrapper .btn-login-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 259px;
  height: 48px;
  color: #fff;
  margin: auto;
  border-radius: 34px;
  background: var(--red-900-2, #2b0001);
  box-shadow: -4px -4px 8px 0px #530909bf, 4px 4px 8px 0px #000000a1;
  border: 0;
}


.swiper-button-prev, .swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: transparent !important;
  width: 55px !important;
  height: 55px !important;
}
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-prev {
  left: 15%;
  top: 45%;
  position: fixed;
  transform: translate(0, -50%);
}
.swiper-button-next {
	right: 15%;
  top: 45%;
  position: fixed;
  transform: translate(0, -50%);
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  content: unset;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-family: unset;
  font-size: unset;
  text-transform: unset !important;
  letter-spacing: unset;
  font-feature-settings: unset;
  font-variant: unset;
  line-height: unset;
}



.btn-login--- {
  width: 176px;
  height: 42px;
  color: #fff;
  margin: auto;
  border-radius: 34px;
  background: var(--red-900-2, #2b0001);
  box-shadow: -4px -4px 8px 0px #530909bf, 4px 4px 8px 0px #000000a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-regis--- {
  width: 176px;
  height: 42px;
  color: #fff;
  margin: auto;
  border-radius: 24px;
  background: linear-gradient(89.82deg, #ba8a45 0.08%, #f6e588 49.47%, #ba8a45 99.84%);
  box-shadow: -4px -4px 8px 0px #53090999, 4px 4px 8px 0px #0000004d;
}
a.btn-login-bottom .text, a.btn-register-bottom .text {
  padding: 8px;
}

.w-bottom-nav-mb{
  display: none;
}

.pc {
  display: block;
}

.mb {
  display: none;
}


@media (max-width: 1300px){
  .swiper-button-prev {
    left: 10%;
  }
  .swiper-button-next {
    right: 10%;
  }
}
@media (max-width: 1200px){
  .swiper-button-prev {
    left: 5%;
  }
  .swiper-button-next {
    right: 5%;
  }
}




@media (max-width: 1199px) {
  .pc-menu{
    display: none;
  }
  .login-wrapper a.sub-menu {
    width: 140px;
    height: 50px;
  }


  .games-select {
    width: 991px;
  }
  .games-select .game {
    width: 150px;
    height: 85px;
  }
  .games-select .game .title {
    font-size: 15px;
    margin: 10px 0 0 0;
    width: 85px;
    text-align: center;
    color: #fff;
    margin-right: 4px;
    margin-top: 6px;
    margin-left: -8px;
    height: 85px;
  }
  .games-select .game.hit .title {
    margin-top: 0px;
  }
  .games-list-section {
    width: 991px;
    margin: auto;
  }
  .box-game-login {
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
  }



  .login-wrapper .flex .left {
    width: 400px;
  }
}


@media (max-width: 991px) {
	.pc {
		display: none;
	}

	.mb {
		display: block;
	}
  .login-register.flex {
    display: none;
  }
  .login-wrapper .box-menu .menu {
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0;
  }
  a.btn-affiliate.sub-menu.active .text {
    width: 90px;
    margin: auto;
  }


  /* affiliate */
  .affiliate-section {
    display: block;
    justify-content: center;
    width: 360px;
    margin: auto;
  }
  .affiliate-section .affiliate-left, .affiliate-section .affiliate-right {
    width: 100%;
    margin: auto;
  }
  .affiliate-section img{
    width: 100%;
    height: auto;
  }
  .title-gold-img.login-page {
    position: relative;
    width: 100%;
  }
  .title-gold-img h4, .title-gold-img h5 {
    font-size: 22px;
    position: absolute;
    left: 50%;
    top: 10px;
    width: 100%;
    transform: translate(-50%, 0);
  }
  .title-gold-img h5 {
    top: 38px;
    font-size: 14px !important;
  }
  .title-gold-img {
    justify-content: space-between;
  }
  .login-wrapper .flex {
    width: 100%;
  }




  .box-menu.flex{
    position: fixed;
    top: 0;
    z-index: 999;
    height: 100px;
    /* background-color: #550908; */
    background: linear-gradient(
        rgba(85, 9, 8, 1), /* Red with 50% transparency */
        rgba(0, 0, 255, 0)  /* Blue with 50% transparency */
    );
  }
  .home, .promotion, .affiliate{
    padding-top: 2.5rem;
  }
  .affiliate{
    margin-bottom: 5rem;
  }
  .game-landing-section.game{
    padding-top: 4.5rem;
    margin-bottom: 5rem;
  }
  .promotion {
    padding-top: 2rem;
  }
  .login-wrapper .box-menu .menu {
    width: calc(100% - 40px);
    /* margin-bottom: 0; */
  }




  .w-bottom-nav-mb{
    display: block;
  }
  .w-bottom-nav-mb .login-register-section {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(0.5turn, rgba(43, 0, 1, 1), rgba(43, 0, 1, 0.9));
    box-shadow: 0px -13px 10px rgba(43, 0, 1, 0.9);
  }
  .login-register-section--- {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    margin-bottom: 1rem;
  }
  .login-container .login-logo > img {
    width: 276px;
  }



  /* game */
  .games-select .game {
    display: block;
    width: 65px;
    height: 65px;
    padding: 0;
    margin: 1rem 2px;
    border-radius: 12px;
  }
  .games-select .game .img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .games-select .game {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/bg-menu-mb.webp);
  }

  .games-select .game.active {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/bg-menu-mb-active.webp);
  }
  .games-select .game.hit .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/hit.webp);
  }
  .games-select .game.hit:hover .img, .games-select .game.hit.active .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/hit.gif);
  }

  .games-select .game.sport .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/sport.webp);
  }
  .games-select .game.sport:hover .img, .games-select .game.sport.active .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/sport.gif);
    width: 55px;
  }

  .games-select .game.casino .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/casino.webp);
  }
  .games-select .game.casino:hover .img, .games-select .game.casino.active .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/casino.gif);
    width: 55px;
  }

  .games-select .game.lotto .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/lotto.webp);
  }
  .games-select .game.lotto:hover .img, .games-select .game.lotto.active .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/lotto.gif);
    width: 55px;
  }
  .games-select .game.lotto .img {
    margin-top: -14px;
  }

  .games-select .game.slot .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/slot.webp);
  }
  .games-select .game.slot:hover .img, .games-select .game.slot.active .img {
    background-image: url(https://fs.cdnxn.com/lsm99queen-next/menu/landing/slot.gif);
    width: 55px;
  }
  .games-select .game.slot .img {
    margin-top: -9px;
    margin-bottom: -15px;
  }

  .games-select .game .img, .games-select .game.active .img {
    width: 45px;
    height: 100%;
    margin: -15px auto -10px;
  }
  .games-select .game .title, .games-select .game.hit.active .title {
    margin: -12px auto 0;
    width: 65px;
    height: auto;
  }
  .games-select {
    height: 85px;
    margin: 0 auto 1rem;
    width: 100%;
  }
  .games-list-section {
    width: 365px;
  }
  .games-box-img {
    width: 118px;
    height: 118px;
  }
  .box-game-login {
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }


  /* home */
  .login-wrapper .home .flex {
    display: block;
  }
  .login-wrapper .flex .left, .login-wrapper .flex .right {
    width: 350px;
    margin: auto;
    text-align: center;
  }
  .logo-login-right {
    width: 360px;
  }
  .landing-detail-- {
    width: 360px;
    height: 182px;
  }
  .login-wrapper .flex .right .landing-detail {
    width: 340px;
    height: 162px;
  }
  .login-wrapper .flex .right .landing-detail p {
    font-size: 11px;
    margin-bottom: 0px;
  }
  .login-wrapper .btn-login-bottom, .login-wrapper .btn-register-bottom {
    width: 175px;
  }


  .login-container > .login-wrapper {
    margin-bottom: 5rem;
  }


  .swiper-slide.swiper-slide-active > img {
    width: 368px;
    height: auto;
    object-fit: cover;
  }
  .swiper-slide img {
    display: block;
    width: 315px;
    height: auto;
    object-fit: cover;
    margin: auto;
  }


  
  .swiper-button-prev {
    left: 0;
    top: 16%;
    position: absolute;
  }
  .swiper-button-next {
    right: 0;
    top: 16%;
    position: absolute;
  }
  .swiper-button-prev, .swiper-button-next {
    width: 35px !important;
    height: 35px !important;
  }


  .mySwiperMb2{
    padding-top: 1rem;
    height: auto;
  }
  .slide-menu-mb {
    margin-top: .5rem;
  }


}


@media (max-width: 767px) {
  .login-wrapper a.sub-menu {
    width: 88px;
    height: 50px;
  }

  .games-select .game {
    width: 62px;
    height: 62px;
  }
  .games-select .game .title {
    font-size: 13px;
  }
  .games-select .game .title, .games-select .game.hit.active .title {
    margin: -10px auto 0;
  }


  .login-wrapper .box-menu .menu {
    width: 100%;
  }
  .mySwiperMb2 {
    /* height: auto;
    padding-top: 4rem; */
  }
  .swiper-slide.swiper-slide-prev.swiper-main-section-mb {
    margin-top: 4.1rem;
  }


  .box-game-login {
    margin-bottom: 2rem;
  }

  .box-game-login {
    margin-bottom: 2rem;
  }
}

@media (max-height: 967px){
  .mySwiperMb2 {
    height: auto;
  }
  .swiper-button-prev, .swiper-button-next {
    top: 12%;
  }
  /* .mySwiperMb2 {
    padding-top: 1rem;
  } */
}



@media (max-width: 429px) {
  .games-select .game .title, .games-select .game.hit.active .title{
    margin: -10px auto 0;
    font-size: 12px;
  }
  .games-select .game {
    height: 60px;
    margin: 1rem 1px;
    width: 60px;
  }
  .box-game-login {
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    padding: 0;
  }
  .games-box-img {
    width: 180px;
    height: 180px;
    margin: auto;
  }
}

@media (max-width: 420px) {
  .games-select .game {
    height: 50px;
    width: 50px;
  }
  .games-select .game .title, .games-select .game.hit.active .title {
    margin: 6px auto 0;
    font-size: 10px;
    width: 50px;
  }
  .games-select {
    height: 70px;
    margin: 0 auto .5rem;
  }
}

@media (max-width: 375px) {
  .swiper-slide.swiper-slide-active > img {
    width: 330px;
  }
  .swiper-slide img {
    padding: .5rem;
  }
}


@media (max-width: 360px) {
}

@media (max-height: 800px){
}